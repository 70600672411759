import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';

const Szims = React.lazy(() => import('./projects/szims'));
const Bitcrafts = React.lazy(() => import('./projects/bitcrafts'));
const KnottyPine = React.lazy(() => import('./projects/knottypine'));
const Ilonka = React.lazy(() => import('./projects/ilonka'));
const Askociety = React.lazy(() => import('./projects/askociety'));
const Entrly = React.lazy(() => import('./projects/entrly'));
const LetsName = React.lazy(() => import('./projects/letsname'));
const IdopontExpress = React.lazy(() => import('./projects/idopontexpress'));
const Tarca = React.lazy(() => import('./projects/tarca'));

let Target;

switch (process.env.REACT_APP_TARGET) {
    case 'szims':
        Target = Szims;
        break;
    case 'bc':
        Target = Bitcrafts;
        break;
    case 'ido':
        Target = IdopontExpress;
        break;
    case 'ilonka':
        Target = Ilonka;
        break;
    case 'entrly':
        Target = Entrly;
        break;
    case 'ask':
        Target = Askociety;
        break;
    case 'kp':
        Target = KnottyPine;
        break;
    case 'ln':
        Target = LetsName;
        break;
    case 'tarca':
        Target = Tarca;
        break;
    default:
        Target = Bitcrafts;
        break;
}

ReactDOM.render(
    <Suspense fallback={<div />}>
        <Target />
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
